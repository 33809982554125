.craving-opt-container {

    .craving-opt-buttons {
        margin-top: 10vh;
        height: fit-content;
        width: 22vw;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
        .craving-opt-button {
            cursor: pointer;

            &:hover {
                svg {  
                    background: linear-gradient(to bottom right, #EA3D3D,  #CE22B2);
                }
            }

            svg {
                width: 8vw;
                height: 8vw;
                border-radius: 100%;
                padding: 0.5vw;
                background: linear-gradient(to bottom right, #CE22B2,  #EA3D3D);
            }

            .text {
                font-size: 3vw;
                text-align: center;
                font-family: "Inknut Antiqua";
                color: white;
                background: linear-gradient(to bottom right, #CE22B2, #EA3D3D);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
}
