@media only screen and (max-width: 768px) {
    .event-review {
        .event-wrapper {
            width: 80vw;
            height: 100vw;
            position: absolute;
            top: 15%;
            left: 10%;

            &.confirm {
                .star-wrapper {
                    font-size: 8vw;
                }
            }

            .logo {
                font-size: 8vw;

                &.ticket-review {
                    font-size: 5vw;
                }
            }

            .banner-wrapper {
                .banner {
                    width: 70vw;
                    height: 35vw;
                }

                .upload-card {
                    top: 30vw;
                    left: 30%;
                    font-size: 5vw;
                }
            }

            .title, .desc {
                font-size: 4.5vw;
            }

            .desc {
                font-size: 3.5vw;
            }

            .button {
                width: 25vw;
                font-size: 4vw;
                padding: 2vw;
                
            }

            .form-control {
                margin-top: 5vw;
                width: 45vw;
                height: 1.5vw;
                font-size: 4vw;
                padding: 3vw;

                &.desc {
                    width: 65vw;
                    height: 30vw;
                }
            }

            .star-wrapper {
                font-size: 15vw;
            }
        }

        .event-card-wrapper {
            width: 90vw;
            position: relative;
            top: 35vw;
            
            .event-review-card {
                height: 37vw;

                .card-header {
                    .seperator-np img {
                        width: 6vw;
                        height: 6vw;
                    }

                    .seperator-np .name, .star-wrapper {
                        font-size: 5vw;
                    }
                }

                .desc {
                    font-size: 5vw;
                }

                .date-complete {
                    bottom: -6vw;
                    left: 2vw;
                }
            }

            .button {
                width: 40vw;
                font-size: 6vw;
            }
        }
    }
}
