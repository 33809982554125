.popup-container {
    
    .popup-background {
        width: 99vw;
        height: 99vh;
        z-index: 5;
        position: fixed;
        top: 0;
    }

    .popup-content {
        width: 95vw;
        height: 45vw;
        z-index: 6;
        position: fixed;
        top: 2vw;
        left: 2vw;
    
        &.alert-container {
            background-color: #121212;
            width: 30vw;
            height: 12vw;
            padding: 1vw;
            border-radius: 2vw;
            top: 15vw;
            left: 33vw;

            .title {
                color: white;
                margin-right: auto;
                margin-left: auto;
                margin-top: 2vw;
                margin-bottom: 2vw;
                width: fit-content;
                font-size: 3vw;
            }

            .button {
                color: white;
                width: 20vw;
                margin-right: auto;
                margin-left: auto;
                text-align: center;
                font-size: 2vw;
                border-radius: 2vw;
                padding: 1vw;
                cursor: pointer;
                background: linear-gradient(to bottom right, #ff5de5, #ef4444);
            }
        }
    }
}
