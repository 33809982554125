@import url('https://fonts.googleapis.com/css2?family=Expletus+Sans:ital,wght@0,400..700;1,400..700&display=swap');

.EventsPage {
    .eventContents {

        .featured-event {
            border-radius: 2vw;
            height: 40vw !important;
            background-color: #ffffff;
            padding-top: 2vw;
            padding-bottom: 2vw;
            box-shadow: 10px 11px 20px rgba(0, 0, 0, 0.25);

            .title {
                text-align: center;
                width: fit-content;
                margin-right: auto;
                margin-left: auto;
                font-size: 4vw !important;
            }

            .slide {
                width: 80vw;
                margin-right: auto;
                margin-left: auto;
                margin-top: 5vw;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .left, .right {
                    transform: rotate(90deg);
                    fill: rgb(61, 58, 58);
                    width: 5vw;
                    height: 5vw;
                    margin-top: auto;
                    margin-bottom: auto;
                    cursor: pointer;
                }

                .right {
                    transform: rotate(-90deg);
                }

                .content {
                    margin-left: 2vw;
                    margin-right: 2vw;
                    display: flex;
                    flex-direction: row;

                    img {
                       width: 40vw;
                       height: 25vw;
                       border-radius: 2vw;

                    }

                    .info {
                        display: flex;
                        flex-direction: column;
                        height: 25vw;
                        margin-top: auto;
                        margin-bottom: auto;
                        margin-left: 1vw;
                        gap: 1vw !important;

                        .title-cost-date {
                            .title {
                                font-size: 2.5vw !important;
                            }

                            .loc-url {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                width: fit-content;
                                margin-right: auto;
                                margin-left: auto;
                                margin-top: 1.5vh;
                                margin-bottom: 1vw;
                                gap: 2vw;
                        
                                .bullet {
                                    width: 1vw;
                                    height: 1vw;
                                    border-radius: 2vw;
                                    margin-top: auto;
                                    margin-bottom: auto;
                                    background: linear-gradient(to bottom right, #D73EE4, #FF1414 100%);
                                }
                        
                                .date {
                                    color: rgb(158, 103, 103);
                                    font-family: "Inknut Antiqua";
                                    font-size: 1vw;
                                    margin-top: auto;
                                    margin-bottom: auto;
                                }
                        
                                .cost-range {
                                    background-image: linear-gradient(to bottom right, #fb4f8c, #51062a);
                                    -webkit-background-clip: text;
                                    background-clip: text;
                                    color: transparent;
                                    font-size: 1vw;
                                    margin-top: auto;
                                    margin-bottom: auto;
                                    font-family: "Inknut Antiqua";
                                }
                            }
                        }

                        .desc {
                            color: rgb(19, 19, 19);
                            margin-top: auto;
                            margin-bottom: auto;
                            font-size: 1.5vw !important;
                            width: 100%;

                            display: -webkit-box;
                            -webkit-line-clamp: 8; /* Number of lines to show before truncating */
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            height: 19vw; /* Adjust this based on line height and number of lines */
                            line-height: 2vw; /* Adjust this based on your text's line height */
                        }

                        .button {
                            width: 15vw;
                            height: fit-content;
                            border-radius: 1.5vw;
                            padding: 1.5vw;
                            color: white;
                            text-align: center;
                            font-size: 1.5vw;
                            margin-left: auto;
                            margin-right: auto;
                            background: linear-gradient(to top right, #c740f8, #f1499b 60%);
                            box-shadow: 10px 11px 20px rgba(0, 0, 0, 0.25);
                            cursor: pointer;

                            
                        }
                    }
                }
            }
        }

        .container {
            width: 90vw;
            margin-right: auto;
            margin-left: auto;
            margin-top: 10vh;
            margin-bottom: 10vh;

            .title {
                background: #FF1083;
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
                font-size: 1.5vw;
                font-family: "Expletus Sans";
            }
            
            &-events {
                overflow-x: auto;
                display: flex;
                flex-direction: row;
                gap: 3vw;
                padding: 2vw;
            }
        }

        .no-content {
            width: fit-content;
            background-image: linear-gradient(to bottom left, #FFE83F, #F15BB5);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            margin-right: auto;
            margin-left: auto;
            font-size: 3vw;
            font-family: "Expletus Sans";
            text-align: center;
        }
    }
}
