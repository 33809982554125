.ReservePage {
    height: 98.2vh;
    width: 99.6vw;
    background: linear-gradient(250deg, #DFFFFF 0%, rgba(229, 255, 253, 0.49) 51.04%, #E4D8FF 83.85%, #F8CEFF 100%);
}

.ReservePage .content {
    display: flex;
    flex-direction: row;
    column-gap: 5vw;
    width: 80vw;
    margin-top: 8vh;
    margin-right: auto;
    margin-left: auto;
}

.ReservePage .form-container {
    background: white;
    border: 1px solid #EFEFEF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 30vw;
    border-radius: 10px;
    padding: 10px;
}


.ReservePage .form-container .header {
    color: #000;
    text-align: center;
    font-size: 60px;
    font-family: "Inknut Antiqua";
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ReservePage .form-container .sub-header {
    color: #414141;
    text-align: center;
    font-size: 18px;
    font-family: "Inknut Antiqua";
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ReservePage .form-container .link {
    display: grid;
    grid-template-columns: 15vw 10vw;
    column-gap: 50px;
}

.ReservePage .form-container .label {
    display: flex;
    flex-direction: column;
    color: #000;
    font-size: 20px;
    font-family: "Inknut Antiqua";
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;
    margin-top: 25px;
}

.ReservePage .form-container .label input, .ReservePage .form-container .label textarea {
    border: none;
    border-bottom: 1px solid black;
    margin-top: 10px;
}

.ReservePage .form-container .label input:focus, .ReservePage .form-container .label textarea:focus {
    border: none;
    border-bottom: 1px solid black;
    outline: none;
}

.ReservePage .form-container .submit {
    border: 1px solid rgb(73, 73, 73);
    border-radius: 50px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.ReservePage .form-container .submit .btn-text {
    color: #4b4b4b;
    text-align: center;
    font-size: 45px;
    font-family: "Inknut Antiqua";
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ReservePage .form-container .submit .btn-ico {
    font-size: 45px;
}

@media only screen and (max-width: 768px) {
    .ReservePage .content .page-food {
        display: none;
    }

    .ReservePage .form-container {
        width: 80vw;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        bottom: 6vh;
    }

    .ReservePage .form-container .header {
        font-size: 35px
    }

    .ReservePage .form-container .sub-header {
        font-size: 12px;
    }

    
    .ReservePage .form-container .link {
        grid-template-columns: 35vw 30vw;
    }

    .ReservePage .form-container .label {
        font-size: 15px;
        margin-top: 10px;
        margin-bottom: 15px;
    }

    .ReservePage .form-container .submit .btn-text {
        font-size: 25px;
    }

    .ReservePage .form-container .submit .btn-ico {
        font-size: 25px;
    }
}

