body, html {
  background-color: #ffffff;
  height: 100%;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar{
  height: 1vw;
  width: 1vw;
  background: #ffffff;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f7f6f6; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #adadad;
  border-radius: 1vw;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #535353; 
}

::-webkit-scrollbar-thumb:horizontal{
    background: #adadad;
    border-radius: 1vw;
}
