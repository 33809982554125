@media only screen and (max-width: 768px) {
    .EventsPage {
        .eventContents {
            width: 99vw;
            display: flex;
            flex-direction: column;

            .container {
                margin-left: auto;
                margin-right: auto;
                width: 99vw;

                .title {
                    font-size: 5vw !important;
                    text-align: center;
                }
            }

            .featured-event {
                margin-bottom: 0vw !important;
                padding-bottom: 5vw;
                height: 50vw !important;

                .title {
                    font-size: 8vw !important;
                }

                .slide {
                    width: 96vw;
                    margin-top: 2vw;

                    .left, .right {
                        width: 10vw;
                        height: 10vw;
                    }

                    .content {

                        img {
                            width: 40vw;
                            height: 35vw;
                        }

                        .info {
                            height: 30vw;

                            .title-cost-date {
                                gap: 0vw !important;

                                .title {
                                    font-size: 3vw !important;
                                }

                                .loc-url {
                                    margin-top: 1vw !important;
                                    margin-bottom: 0.5vw !important;

                                    .date, .cost-range {
                                        font-size: 3vw;
                                    }
                                }
                            }

                            .desc {
                                width: 45vw;
                                font-size: 2.5vw !important;
                                margin-bottom: 2vw;
                            }

                            .button {
                                width: 20vw;
                                font-size: 4vw;
                                margin-bottom: 2vw !important;
                            }
                        }
                    }
                }
            }
        }

        &.bottom .footer {
            position: inherit;
        }
    }
}