@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Expletus+Sans:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Barcode+128+Text&display=swap');

.HomePage {
    .header {
        padding: 0;
        margin: 0;
        height: 99vh;
        
        .navbar-unsigned  {
            z-index: 2;
        }

        .header-img {
            position: relative;
            top: -63vh;

            img {
                width: 98.8vw;
                height: 99vh;
            }

            .shadow {
                position: relative;
                top: -60vh;
                width: 98.8vw;
                height: 60vh;
                background: linear-gradient(to bottom, #2121210e, #0d0d0df6, #070707 );
            }
        }

        .action-container {
            position: relative;
            top: 15vh;
            text-align: center;
            z-index: 1;

            .logo {
                font-size: 5vw;
            }

            .title {
                margin-right: auto;
                margin-left: auto;
                color: #FFFFFF;
                font-family: "Dela Gothic One";
                font-size: 5vw;
                width: 66vw;
            }

            .buttons-container {
                width: fit-content;
                margin-right: auto;
                margin-left: auto;
                display: flex;
                flex-direction: row;
                padding: 1vw;
    
                .button-opt {
                    color: white;
                    background: linear-gradient(to bottom right, #FB4F8C, #FB4FAC, #F655EF, #F15BB5 );
                    padding: 1.5vw;
                    font-size: 2vw;
                    margin-right: 2vw;
                    cursor: pointer;
    
                    &:hover {
                        background: transparent;
                        border: 1px solid white;
                    }
    
                    &.blend {
                        background: transparent;
                        border: 1px solid white;
    
                        &:hover {
                            background: linear-gradient(to bottom right, #FB4F8C, #FB4FAC, #F655EF, #F15BB5 );
                            border: 1px solid transparent;
                        }
                    }
                }
            }
        }
    }

    .our-services {
        padding: 4vw;

        .title {
            color: #FF1083;
            font-family: "Expletus Sans";
            font-size: 1.5vw;
            text-shadow: 2px 4px 0px rgb(0, 0, 0);
        }

        .split-text {
            display: flex;
            flex-direction: row;

            &-container {
                .title {
                    color: white;
                    width: 45vw;
    
                    .text {
                        font-size: 5vw;
                    }
    
                    .text-platform {
                        position: relative;
                        top: -10.5vh;
                        left: 9vw;
                        font-size: 4.5vw;
                        font-family: "Libre Barcode 128 Text";
                        height: fit-content;
                    }
                }

                .card-offer {
                    background-color: #111111;
                    padding: 2vw;
                    width: 30vw;
                    margin-right: auto;
                    margin-left: auto;
                    margin-bottom: 3vh;

                    svg { 
                        width: 8vw;
                        height: 8vw;
                    }

                    .title {
                        color: white;
                        font-size: 2.5vw;
                        font-family: "Expletus Sans";
                    }

                    .description {
                        font-family: "Expletus Sans";
                        color: white;
                        font-size: 1vw;
                    }
                }
            }
        }
    }
}
