@media only screen and (max-width: 768px) {
    .event-page {
        &-container {
            .header {
                .banner {
                    height: 80vw;
                    width: 100vw;
                }

                .title-stats {
                    .title {
                        font-size: 6vw;
                        margin-top: 3vh;
                        text-align: center;
                        margin-right: auto;
                        margin-left: auto;
                    }

                    .small-stats {
                        gap: 10vw;

                        .stats {
                            font-size: 4vw;
                            width: 25vw;
                        }
                    }
                }
            }

            &.signed .seperator {
                margin-top: -0vh;
                margin-bottom: 2vh;
            }

            &.unsigned .seperator {
                margin-top: -0.5vh;
                margin-bottom: 2vh;
            }

            .empty {
                font-size: 5vw;
            }

            .reviews {
                .review-summary {
                    width: 90vw;
                    height: 45vw;

                    .rating {
                        font-size: 10vw;
                    }
                    
                    .star-wrapper {
                        font-size: 12vw;
                    }

                    .context {
                        font-size: 8vw;
                    }
                }

                .content {
                    .event-review-card {
                        width: 92vw;
                        height: 40vw;

                        .card-header {
                            .seperator-np img {
                                width: 6vw;
                                height: 6vw;
                            }
        
                            .seperator-np .name, .star-wrapper {
                                font-size: 5vw;
                            }
                        }

                        .desc {
                            font-size: 5vw;
                        }
        
                        .date-complete {
                            bottom: -6vw;
                            left: 2vw;
                        }
                    }
                }
            }

            .photo-gallery {
                display: flex;
                flex-direction: column;

                .photo {
                    margin-right: auto;
                    margin-left: auto;

                    .card {
                        font-size: 5vw;
                        padding: 2vw;
                        border-radius: 3vw;
                        top: 12vw;
                        left: 3vw;
                    }

                    img {
                        width: 80vw;
                        height: 80vw;
                    }
                }
            }

            .content {
                display: block;
                gap: 2vw;
                margin-bottom: 20vw;

                .content-container {
                    width: 95vw;

                    .container {
                        padding: 2vw;
                        height: 9vw;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        &.date,
                        &.loc {
                            justify-content: left;
                            gap: 6vw;
                        }
                    }

                    .org {

                        img {
                            height: 9vw;
                            width: 9vw;
                        }

                        .details {
                            width: fit-content;

                            .title {
                                font-size: 4vw;
                            }

                            .small-stats {
                                .stats {
                                    font-size: 3.5vw;
                                }
                            }
                        }

                        .follow {
                            font-size: 4.5vw;
                            width: 20vw;
                            height: 7vw;
                            width: fit-content;
                        }
                    }

                    svg {
                        width: 8vw;
                        height: 8vw;
                    }

                    .date,
                    .loc-text {
                        font-size: 4vw;
                    }

                    .desc {
                        font-size: 5vw;
                        height: fit-content;
                        display: none;
                        &.mobile {
                            display: block;

                            &.zoom {
                                position: fixed;
                                top: 22vw;
                                left: -1vw;
                                height: 85vh;
                                overflow-y: auto;
                            }
                        }
                    }
                }

                .price-badge {
                    margin-bottom: 10px;
                    height: 25vw;
                    position: inherit;
                    border-radius: 2vw;

                    .range {
                        font-size: 8vw;
                    }

                    .button {
                        font-size: 6vw;
                        padding: 3vw;
                        width: 40vw;
                        height: 7vw;
                        margin-right: auto;
                        margin-left: auto;
                    }
                }
            }
        }
    }
}