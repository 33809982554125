@import url('https://fonts.googleapis.com/css2?family=Expletus+Sans:ital,wght@0,400..700;1,400..700&display=swap');

.footer {
    width: 98.9vw;
    height: 20vh;
    position: relative;
    border-top: 1px solid rgb(83, 83, 83);
    margin-right: auto;
    margin-left: auto;
    color: rgb(0, 0, 0);
    font-family: "Expletus Sans";
    font-size: 1vw;
    //background-color: white;

    .top-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 98vw;
        margin-right: auto;
        margin-left: auto;
       

        .logo-links {
            .logo {
                margin-top: 2vh;
                margin-bottom: 2vh;
                font-size: 2.5vw;
            }
    
            .links {
                display: flex;
                flex-direction: column;
                margin-right: 2vw;
        
                a {
                    margin-bottom: 1.5vh;
                    text-decoration: none;
                    font-family: "Inknut Antiqua";
                    color: rgb(0, 0, 0);
                    padding: 0.5vw;
    
                    &:hover {
                        background-image: linear-gradient(to bottom right, #FF5DE5, #EF4444);
                        -webkit-background-clip: text;
                        background-clip: text;
                        color: transparent;
                    }
                }
            }
        }
        
        .moto {
            width: 40vw;
            height: fit-content;
            margin-right: auto;
            margin-left: auto;
            margin-top: auto;
            margin-bottom: auto;
            color: rgb(255, 255, 255);
            background-image: linear-gradient(to bottom right, #FF5DE5, #EF4444);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            font-size: 3vw;
            font-family: "Inknut Antiqua";
            text-align: center;
        }

        .social-links {
            margin-top: 2vh;
            margin-bottom: 2vh;
            font-size: 1.1vw;
            text-align: center;

            .title {
                margin-bottom: 2vh;
            }
            
            .links {
                svg {
                    width: 4vw;
                    height: 5vh;
                    cursor: pointer;
                }
            }
        }
    
    }

    .bottom-row {
        font-size: 1.5vw;
        font-family: "Inknut Antiqua";
        text-align: center;
        position: relative;
        top: 2.5vh;
        border-top: 1px solid rgb(0, 0, 0);
        //background-color: white;
    }
}

@media only screen and (max-width: 768px) {
    .footer {
        .top-row {
            .text-logo {
                width: 20vw;
                height: 8vh;
            }

            .moto {
                font-size: 12px;
                right: -2vw;
                top: 5vw;
            }
        }

        .links a {
            margin-bottom: 6px;
        }

        .bottom-row {
            top: 2vh
        }
    }
}
