@media only screen and (max-width: 768px) {
    .login {
        .title {
            font-size: 5vw !important;
        }

        .input-group {
            margin-top: 6vw;
            width: 50vw;

            .form-control {
                margin-bottom: 6vw;
            }
        }

        .button {
            width: 32vw;
            font-size: 5.5vw;
        }

        .login-text {
            margin-top: 5vw;
            font-size: 2vw;
        }
    }
}
