@import url("https://fonts.googleapis.com/css2?family=Expletus+Sans:ital,wght@0,400..700;1,400..700&display=swap");

@media only screen and (max-width: 768px) {
    .navbar-unsigned {
        &-opt-text-container, &-opt-button-container {
            display: none;
        }

        .logo-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 98vw;

            .logo {
                font-size: 6vw;
                display: flex;
                margin-top: auto;
                margin-bottom: auto;
            }

            .menu-bar, .menu-bar-x {
                display: block;
                cursor: pointer;
                margin-right: 2vw;
            }

            .menu-bar {
                width: 8vw;
                height: 8vw;
                color: white;
                fill: white;

                &-x {
                    font-size: 6vw;
                    color: white;
                    font-family: "Expletus Sans";
                }
            }
        }

        &.mobile-clicked {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 0;
            background: black;
            height: 100vh;
            width: 100vw;
            z-index: 5;

            .logo-container {
                margin-top: 5vw;
            }

            .navbar-unsigned-opt-text-container {
                border: 1px solid white;
            }

            .navbar-unsigned-opt-text-container, .navbar-unsigned-opt-button-container {
                display: flex;
                flex-direction: column;
                margin-right: auto;
                margin-left: auto;
                width: 95vw;
                gap: 2vw;
                border-radius: 2vw;
                margin-top: 10vw;

                .opt-text {
                    font-size: 10vw;
                }

                .opt-button {
                    width: 40vw;
                    font-size: 10vw;
                    border-radius: 3vw;
                }
            }

            .navbar-unsigned-opt-button-container {
                flex-direction: row;
            }
        }
    }
}
