@import url("https://fonts.googleapis.com/css2?family=Expletus+Sans:ital,wght@0,400..700;1,400..700&display=swap");

.user-home-page {
    .navbar-signed {
        z-index: 2;
    }

    .header {
        position: relative;
        top: -3.5vw;

        .banner {
            width: 99vw;
            height: 30vw;
        }

        .profile-header {
            display: flex;
            flex-direction: column;

            &-content {
                display: flex;
                flex-direction: row;
                margin-top: 1vw;

                .profile-header-img {
                    width: 7vw;
                    height: 6vw;
                    border-radius: 2vw;
                    padding: 1vw;
                }

                .name-stats {
                    display: flex;
                    flex-direction: column;

                    .name {
                        color: transparent;
                            background: linear-gradient(to bottom right, #CE22B2, #EA3D3D);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        font-family: "Expletus Sans";
                        font-size: 3.5vw;
                    }

                    .stats {
                        display: flex;
                        flex-direction: row;
                        
                        &-data {
                            background-image: linear-gradient(to bottom right, #ff5555, #ff3bb4);
                            -webkit-background-clip: text;
                            background-clip: text;
                            color: transparent;
                            font-size: 2vw;
                            font-family: "Expletus Sans";
                        }

                        .bullet {
                            width: 1vw;
                            height: 1vw;
                            border-radius: 1vw;
                            background-image: linear-gradient(to bottom right, #ff5555, #ff3bb4);
                            
                            margin-top: auto;
                            margin-bottom: auto;
                            margin-right: 1vw;
                            margin-left: 1vw;
                        }
                    }
                }
            }

            .buttons-container {
                margin-top: 1vw;
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: 5vw;

                .buttons {
                    background-image: linear-gradient(to bottom right, #ff5555, #ff3bb4);
                    width: 10vw;
                    height: 3vw;
                    border-radius: 0.5vw;
                    color: white;
                    font-size: 2vw;
                    font-family: "Expletus Sans";
                    text-align: center;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    &:hover {
                        background-image: linear-gradient(to bottom left, #ff5555, #ff3bb4);
                    }
                }
            }
        }

        .seperator {
            width: 96vw;
            height: 0.2vw;
            background: linear-gradient(to bottom right, #ff5555, #ff3bb4);
            border-radius: 1.5vw;
            margin-top: 2vw;
            margin-bottom: 2vw;
            margin-right: auto;
            margin-left: auto;
        }
    }

    .profile {
        .container {
            .title { 
                color: #FF1083;
                font-size: 3vw;
                font-family: "Expletus Sans";
            }

            .no-content {
                color: white;
                font-size: 5vw;
                text-align: center;
                width: fit-content;
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 2vw;
                margin-top: 2vw;
                padding: 0.5vw;
                border-radius: 1vw;
                background: linear-gradient(to bottom left, #ff5de5, #ef4444);;
            }

            .container-events {
                display: flex;
                flex-direction: row;
                gap: 10vw;
                overflow-x: auto;
                margin-bottom: 2vw;

                .profile-bubble {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: fit-content;

                    img {
                        width: 6vw;
                        height: 6vw;
                        border-radius: 6vw;
                        padding: 1vw;
                        margin-right: auto;
                        margin-left: auto;
                        cursor: pointer;
                    }

                    .username {
                        color: white;
                        font-size: 1.5vw;
                        font-family: "Expletus Sans";
                        cursor: pointer;
                        text-align: center;
                    }

                    .delete {
                        color: white;
                        font-size: 1.5vw;
                        cursor: pointer;
                        width: fit-content;
                        border-radius: 2vw;
                        padding: 1vw;
                        position: relative;
                        top: 5vh;
                        right: -8vw;

                        &:hover {
                            background: rgb(33, 33, 33);
                        }
                    }
                }

                .EventsCard {
                    .title {
                        font-size: 2vw;
                    }

                    .desc {
                        font-size: 0.8vw;
                    }

                    .loc-url {
                        margin-top: -0.5vw;
                    }
                }
            }
        }
    }
}