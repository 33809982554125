.sign-in {
    .banner {
        width: 30vw;
        height: 65vh;
        border-radius: 2vw;
        margin-right: auto;
        margin-left: auto;
        margin-top: 10vh;
        background: white;
        padding-top: 5vh;
        box-shadow: 10px 11px 20px rgba(0, 0, 0, 0.25);
        /*position: relative;
        left:10vw; */
    
        
        .title {
            width: 20vw;
            margin-right: auto;
            margin-left: auto;
            color: rgb(58, 41, 41);
            
            font-family: "Inknut Antiqua";
            text-align: center;
            font-size: 2.4vw;
        }
    }    
}
