.login {
    
    .input-group {
        margin-top: 10vh;
        height: fit-content;
        width: 22vw;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        flex-direction: column;

        .form-control {
            margin-bottom: 10vh;
            padding: 1vw;
            border: 1px solid white;
            border-radius: 2vw;
            outline: 1px solid white;
            box-shadow: 10px 11px 20px rgba(0, 0, 0, 0.25);
        }
    }

    .button {
        width: 12vw;
        margin-right: auto;
        margin-left: auto;
        padding: 1vw;
        border-radius: 2vw;
        background: linear-gradient(
            to bottom right,
            #F34A4A, #AA49CC
        );
        text-align: center;
        font-size: 1.5vw;
        color: white;
        cursor: pointer;
        box-shadow: 10px 11px 20px rgba(0, 0, 0, 0.25);

        &:hover {
            background: linear-gradient(
                #F34A4A, #AA49CC
            );
        }
    }

    .login-text {
        margin-top: 5vh;
        margin-right: auto;
        margin-left: auto;
        color: #ACACAC;
        font-size: 0.8vw;
        width: fit-content;
        cursor: pointer;
    }

    .error-text {
        display: none;
        color: rgb(59, 37, 37);
        background-color: transparent;
        text-align: center;
        margin: 1vw;
        margin-bottom: auto;
        font-size: 1.5vw;
        height:.1vw;
        //font-weight: bold;
    }
    .error-text.show{
        display: block;
    }
    .banner{
        position: relative;
        left: 30px;
    }
}
