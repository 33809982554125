@import url("https://fonts.googleapis.com/css2?family=Expletus+Sans:ital,wght@0,400..700;1,400..700&display=swap");

.event-review {
    .event-wrapper {
        width: 30vw;
        height: 42vw;
        border-radius: 1vw;
        margin-right: auto;
        margin-left: auto;
        margin-top: 5vh;
        background: linear-gradient(
            to bottom right,
            #DF4B4B, #D74CA8, #8B1B8E, #4D094E
        );

        &.confirm {

            .star-wrapper { 
                font-size: 2vw;
            }
        }

        .logo {
            font-size: 1.5vw;
            text-align: center;
            position: relative;
            top: 1vw;

            &.ticket-review {
                background-image: linear-gradient(to bottom right, #F97C9A, #F80C46, #F377B9, #C147EA, #DF8DFC );
                font-size: 3vw;
                margin-top: 1vw;
                margin-bottom: 2vw;
            }
        }

        .banner-wrapper {
            display: flex;
            justify-content: center;
            margin-top: 0.5vw;
            margin-bottom: 0.5vw;

            .banner {
                width: 25vw;
                height: 15vw;
                border-radius: 2vw;
            }

            .upload-card {
                background: linear-gradient(to bottom right, #B7B7B7, #0000008e, #000000);;
                position: absolute;
                top: 19vw;
                left: 45%;
                width: fit-content;
                padding: 1vw;
                border-radius: 3vw;
                color: white;
                font-size: 1.5vw;
                cursor: pointer;
            }
        }

        .title, .desc {
            font-size: 2vw;
            font-family: "Expletus Sans";
            color: white;
            text-align: center;
            margin-bottom: 1vw;
        }

        .desc {
            font-size: 1.5vw;
        }

        .button {
            background: linear-gradient(to bottom right, #F15BB5, #FFE83F);
            width: 10vw;
            border-radius: 1vw;
            padding: 1vw;
            text-align: center;
            align-items: center;
            color: white;
            font-size: 1.5vw;
            font-family: "Inter";
            cursor: pointer;

            &:hover {
                background: linear-gradient(to bottom left, #FFE83F, #F15BB5);
            }

            &.center {
                margin-right: auto;
                margin-left: auto;
            }
        }

        .form-control {
            background: #ffff;
            width: 15vw;
            height: 2vw;
            padding: 1vw;
            font-size: 1.5vw;
            border-radius: 1vw;
            margin-top: 2vw;
            margin-right: auto;
            margin-left: auto;
            color: black;
            outline: none;
            border: 1px solid white;
            display: flex;

            &::placeholder {
                text-align: center;
            }

            &.desc {
                width: 25vw;
                height: 12vw;
                margin-top: 0vw;
                text-align: left;
                
                &::placeholder {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        .star-wrapper {
            font-size: 5vw;
            text-align: center;
        }

        .button-wrapper {
            display: flex;
            flex-direction: row;
        }
    }

    .event-card-wrapper {
        width: 40vw;
        height: 30vw;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 10vw;
        box-shadow: 10px 11px 20px rgba(0, 0, 0, 0.25);

        .button {
            background: linear-gradient(to bottom right, #F15BB5, #FFE83F);
            width: 10vw;
            border-radius: 1vw;
            padding: 1vw;
            text-align: center;
            align-items: center;
            color: white;
            font-size: 1.5vw;
            font-family: "Inter";
            cursor: pointer;
            margin-left: auto;
            margin-right: auto;
            margin-top: 5vw;
            box-shadow: 10px 11px 20px rgba(0, 0, 0, 0.25);
        }
    }
}