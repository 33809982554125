.checkbox {
    width: 1vw;
    height: 1vw;
    background: #DBDBDB;
    border-radius: .25vw;
    padding: .1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.checkbox:hover{
        background-color: rgb(194, 192, 192);
    }
    &.checked {
        background: linear-gradient(to bottom right, #ff5de5, #ef4444);
        
    }

    .checkmark {
        width: 1vw;
        height: 1vw;
        stroke: white;
        padding: auto;
        cursor: pointer;
        
    }
}