.settings {
    .settings-card {
        box-shadow: 5px 6px 20px rgba(0, 0, 0, 0.25);
        background-color: #ffffff;
        width: 50vw;
        margin-right: auto;
        margin-left: auto;
        margin-top: 2vw;
        margin-bottom: 2vw;
        border-radius: 1.5vw;
        padding: 0.5vw;

        &.request-password-change {
            

            .header {
                .button {
                    padding: 0.5vw;
                    text-align: center;
                    color: white;
                    font-family: "Expletus Sans";
                    font-size: 2vw;
                    border-radius: 2vw;
                    width: 10vw;
                    height: fit-content;
                    margin-top: auto;
                    margin-bottom: auto;
                    align-items: center;
                    justify-content: center;
                    background: linear-gradient(to bottom right, #ff5de5, #ef4444);
                    cursor: pointer;

                    &:hover {
                        background: linear-gradient(to bottom left, #ff5de5, #ef4444);
                    }
                }
            }

            .content {
                font-size: 1.5vw;
                font-family: "Expletus Sans";
                color: transparent;
                background: linear-gradient(to bottom right, #CE22B2, #EA3D3D);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                text-align: center;
            }
        }

        &.search-mile-radius {
            .header {

                .miles-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    gap: 2vw;

                    .button,
                    .text {
                        font-size: 2vw;
                        color: white;
                        margin-top: auto;
                        margin-bottom: auto;
                    }

                    .button {
                        padding: 1vw;
                        border-radius: 1vw;
                        width: 1vw;
                        height: 0.5vw;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 2.5vw;
                        cursor: pointer;

                        &.minus {
                            background: #252525;
                        }

                        &.plus {
                            background: linear-gradient(to bottom right, #6F0C0C, #C81BAC);
                        }
                    }
                }
            }
        }

        &.notifications {
            
            .header {
                .save-changes {
                    padding: 1vw;
                    text-align: center;
                    color: white;
                    font-family: "Expletus Sans";
                    font-size: 2vw;
                    border-radius: 2vw;
                    width: 10vw;
                    height: 2vw;
                    margin-top: auto;
                    margin-bottom: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: linear-gradient(to bottom right, #ff5de5, #ef4444);
                    cursor: pointer;

                    &:hover {
                        background: linear-gradient(to bottom left, #ff5de5, #ef4444);
                    }
                }
            }

            .content {

                .notification-container {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    
                    .notification-item {
                        display: flex;
                        flex-direction: column;
                        gap: 1vw;

                        .input-checkbox {
                            display: flex;
                            flex-direction: row;
                            gap: 2vw;

                            .checkbox {
                                margin-top: auto;
                                margin-bottom: auto;
                            }

                            .check-input {
                                font-size: 2vw;
                                font-family: "Expletus Sans";
                                color: transparent;
                                background: linear-gradient(to bottom right, #CE22B2, #EA3D3D);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                text-align: left;
                            }
                        }

                        .text-type {
                            color: transparent;
                            background: linear-gradient(to bottom right, #CE22B2, #EA3D3D);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            font-size: 1vw;
                            width: 55%;
                            margin-right: auto;
                            margin-left: auto;
                            text-align: center;
                        }

                    }
                }

            }
        }
    }

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        top: 0;

        .title {
            color: rgb(255, 255, 255);
            background: linear-gradient(to bottom right, #CE22B2, #EA3D3D);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 3vw;
            padding: 0.5vw;
            font-family: "Expletus Sans";
        }
    }

    .seperator {
        height: 0.1vw;
        background: linear-gradient(to bottom right, #eb3072, #2e041e);
        border-radius: 1vw;
        margin-bottom: 2vw;
    }

    .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 5vw;
        padding: 1vw;
        

        .container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 2vw;

            .input-container {
                .title-input {
                    color: transparent;
                    background: linear-gradient(to bottom right, #CE22B2, #EA3D3D);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-size: 2vw;
                    font-family: "Expletus Sans";
                }

                .input {
                    border-radius: 2vw;
                    padding: 1vw;
                    outline: none;
                    border: none;
                    background: hsl(0, 0%, 100%);
                    color: rgb(0, 0, 0);
                    box-shadow: 5px 6px 20px rgba(0, 0, 0, 0.25);
                    font-family: "Expletus Sans";
                    width: 15vw;
                    margin-top: 1vw;
                }
            }

            .profile-container {
                margin-right: auto;
                margin-left: auto;

                img {
                    border-radius: 2vw;
                    width: 10vw;
                    height: 10vw;
                    padding: 1vw;
                    position: relative;
                    left: 1.2vw;
                }

                .upload-button {
                    padding: 1vw;
                    text-align: center;
                    color: white;
                    font-family: "Expletus Sans";
                    font-size: 1vw;
                    border-radius: 2vw;
                    width: 12vw;
                    background: linear-gradient(to bottom right, #e224a3, #bf44ef);
                    cursor: pointer;

                    &:hover {
                        background: linear-gradient(to bottom left, #bf44ef, #e224a3);
                    }
                }
            }

            .save-changes {
                padding: 1vw;
                text-align: center;
                color: white;
                font-family: "Expletus Sans";
                font-size: 1vw;
                border-radius: 2vw;
                width: 15vw;
                background: linear-gradient(to bottom right, #ff5de5, #ef4444);
                cursor: pointer;

                &:hover {
                    background: linear-gradient(to bottom left, #ff5de5, #ef4444);
                }
            }
        }
    }
}