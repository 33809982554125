.navbar-signed {
    display: grid;
    grid-template-columns: 20vw 68vw 10vw;
    position: relative;
    top: 2vh;

    .logo {
        font-size: 3vw;
        cursor: pointer;
        margin-left: 1vw;
        margin-bottom: auto;
        margin-top: auto;
    }

    &-opt-text-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .opt-button {
            background: linear-gradient(to bottom right, #F04E4E, #ff453f);
            width: 8vw;
            border-radius: 1vw;
            padding: 0.8vw;
            text-align: center;
            align-items: center;
            color: white;
            font-size: 1.5vw;
            font-family: "Inter";
            cursor: pointer;
            margin-bottom: auto;
            margin-top: auto;

            &:hover {
                background: linear-gradient(to bottom left, #ff3f3f, #f83ef8);
            }
        }

        input {
            padding: 1vw;
            border: 1px solid white;
            border-radius: 2vw;
            outline: 1px solid white;
            width: 50vw;
            margin-bottom: auto;
            margin-top: auto;
        }

    }

    .menu {
        align-items: right;
        display: flex;
        justify-content: right;
        
        img {
            width: 3vw;
            height: 3vw;
            margin-right: auto;
            margin-left: auto;
            margin-top: auto;
            margin-bottom: auto;
            border-radius: 100%;
            cursor: pointer;
        }
    
        .downArrow {
            display: none;
        }
    }
    
}

.navbar-signed-opt-text-container{
    input[type=text] {
        box-shadow: 10px 11px 20px rgba(0, 0, 0, 0.25);
      }
    
    
}