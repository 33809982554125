.navbar-unsigned {
    display: grid;
    grid-template-columns: 20vw 50vw 28vw;
    position: relative;
    top: 1.5vh;

    .logo {
        font-size: 3vw;
        cursor: pointer;
        margin-left: 1vw;
    }

    .menu-bar, .menu-bar-x {
        display: none;
    }

    &-opt-text-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .opt-text {
            font-family: "Inria Serif", sans-serif;
            font-weight: bold;
            color:transparent;
            background: -webkit-linear-gradient( #F34A4A, #AA49CC);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 1.5vw;
            cursor: pointer;
            
            &:hover {
                background: linear-gradient(to bottom right, #F06F6F, #933EB1);
                width: 10vw;
                border-radius: 50vw;
                padding: 0.8vw;
                text-align: center;
                align-items: center;
                -webkit-text-fill-color: white;
                font-size: 1.5vw;
                font-family: "Inter";
            }
        }

    }

    &-opt-button-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-left: 5vw;

        .opt-button {
            background: linear-gradient(to bottom right, #F06F6F, #933EB1);
            width: 8vw;
            border-radius: 50vw;
            padding: 0.8vw;
            text-align: center;
            align-items: center;
            color: white;
            font-size: 1.5vw;
            font-family: "Inter";
            cursor: pointer;
            box-shadow: 5px 6px 20px rgba(0, 0, 0, 0.4);
            &:hover {
                background: linear-gradient(to bottom left, #933EB1, #F06F6F);
            }
        }
    }
}
