.create-account {
    
    .input-group {
        margin-top: 6vh;
        height: fit-content;
        width: 22vw;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        flex-direction: column;
        .form-control {
            margin-bottom: 6vh;
            padding: 1vw;
            border: 1px solid white;
            border-radius: 2vw;
            outline: 1px solid white;
            box-shadow: 10px 11px 20px rgba(0, 0, 0, 0.25);
        }
    }

    .button {
        width: 12vw;
        margin-right: auto;
        margin-left: auto;
        padding: 1vw;
        border-radius: 2vw;
        background: linear-gradient(
            to bottom right,
            #F34A4A, #AA49CC
        );
        text-align: center;
        font-size: 1.5vw;
        color: white;
        cursor: pointer;
        box-shadow: 10px 11px 20px rgba(0, 0, 0, 0.25);

        &:hover{
            background: linear-gradient(
                to bottom right,
                #AA49CC, #F34A4A
            );
        }
    }
    

    .login-text {
        margin-top: 5vh;
        margin-right: auto;
        margin-left: auto;
        color: #ACACAC;
        font-size: 0.8vw;
        width: fit-content;
        cursor: pointer;
    }
}
