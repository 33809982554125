

.AcceptTeamMemberInvitePage {
    .content {
        display: flex;
        flex-direction: row;
        column-gap: 5vw;
        width: 80vw;
        margin-top: 8vh;
        margin-right: auto;
        margin-left: auto;

        .box-shadow {
            box-shadow: 2px 12px 20px rgba(0, 0, 0, 0.25);
        }

        img {
            width: 50vw;
            height: 35vw;
            margin-top: auto;
            margin-bottom: auto;
        }

        .form-container {
            background: white;
            border: 1px solid #EFEFEF;
            width: 30vw;
            height: 22vw;
            margin-top: auto;
            margin-bottom: auto;
            border-radius: 2vw;
            padding: 2vw;

            .header {
                color: black;
                text-align: center;
                font-size: 2vw;
                font-family: "Inkut Antiqua";
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 1vw;
            }

            .sub-header {
                color: #414141;
                text-align: center;
                font-size: 1vw;
                font-family: "Inkut Antiqua";
                font-style: normal;
                font-weight: 400;
                margin-bottom: 1vw;
                line-height: normal;

                &.inactive {
                    font-size: 2.4vw;
                    background-image: linear-gradient(to bottom right, #f14242, #ec39d5);
                    -webkit-background-clip: text;
                    background-clip: text;
                    color: transparent;
                    position: relative;
                    top: 4vw;
                    display: flex;
                }
            }

            .link {
                display: grid;
                grid-template-columns: 15vw 10vw;
                column-gap: 2vw;

                .label {
                    display: flex;
                }
            }

            .label {
                display: flex;
                flex-direction: column;
                color: black;
                font-size: 1vw;
                font-family: "Inkut Antiqua";
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 1.5vw;
                margin-top: 2vw;

                input, textarea {
                    border: none;
                    border-bottom: 1px solid black;
                    margin-top: 2vw;
                    outline: none;
                }
            }

            .submit {
                background: linear-gradient(to right, #F06F6F, #933EB1);
                border-radius: 2vw;
                padding: 1vw;
                display: flex;
                flex-direction: row;
                column-gap: 0.5vw;
                width: fit-content;
                margin-right: auto;
                margin-lefT: auto;
                text-align: center;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: white;
            }
        }
    }
}