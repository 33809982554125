@import url("https://fonts.googleapis.com/css2?family=Expletus+Sans:ital,wght@0,400..700;1,400..700&display=swap");

@media only screen and (max-width: 768px) {
    .navbar-signed {
        display: flex;
        justify-content: space-between;
        padding-left: 2vw;
        gap: 10vw;

        .logo {
            font-size: 6vw;
            display: flex;
            margin-top: auto;
            margin-bottom: auto;
        }

        .navbar-signed-opt-text-container {
            display: none;
        }

        
        .menu {
            margin-top: auto;
            margin-bottom: auto;

            .downArrow {
                width: 6vw;
                height: 6vw;
                fill: white;
                margin-top: auto;
                margin-bottom: auto;
                margin-right: 2vw;
                display: flex;
                transform: rotate(180deg);

                &.clicked {
                    transform: rotate(0deg);
                }
            }
            
            img {
                width: 10vw;
                height: 10vw;
                margin-right: 3vw;
            }

            .menu-content {
                position: fixed;
                top: 15vw;
                background: linear-gradient(to bottom right, #f83ef8, #ff453f);
                width: fit-content;
                border-radius: 2vw;
                padding: 2vw;
                color: white;
                font-size: 8vw;
                font-family: "Inter";
                cursor: pointer;
                text-align: center;
                gap: 6vw;
                display: flex;
                flex-direction: column;

                &:hover {
                    background: linear-gradient(to bottom left, #ff3f3f, #f83ef8);
                }
            }
        }

        &.searchClicked {
            .logo {
                display: none;
            }

            .navbar-signed-opt-text-container {
                display: flex;
                justify-content: space-between;
                gap: 5vw;
                margin-top: auto;
                margin-bottom: auto;
    
                input {
                    display: flex;
                    width: 80vw;
                    height: 7vw;
                }
    
                .opt-button {
                    display: none;
                }
            }

            .menu {
                img {
                    display: none;
                }

                .downArrow {
                    margin-left: -20vw;
                }
            }
        }
    }
}