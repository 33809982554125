@import url('https://fonts.googleapis.com/css2?family=Expletus+Sans:ital,wght@0,400..700;1,400..700&display=swap');

.event-ticket {
    .container {
        background: linear-gradient(to bottom right, #550909, #530154);
        width: 92vw;
        height: 35vh;
        margin-top: 14vw;
        margin-right: auto;
        margin-left: auto;
        align-self: center;
        justify-self: center;
        border-radius: 2vw;
        padding: 0.5vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .event-infor {
            img {
                width: 55vw;
                height: 35vh;
                border-radius: 2vw;
            }

            .title {
                font-size: 3vw;
                width: 22vw;
            }

            .date {
                font-size: 1vw;
                margin-top: 7vw;
            }

            .title, .date {
                position: relative;
                top: -17.5vw;
                left: 2vw;
                color: white;
                font-family: "Expletus Sans";
            }
        }

        .seperator {
            border: 1px dashed white;
            margin-left: 1vw;
            margin-right: 1vw;
        }

        .ticket-infor {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 1vw;

            .infor {
                font-size: 1vw;
                color: white;
                font-family: "Expletus Sans";
                text-align: center;
            }
        }

        canvas {
            border-radius: 2vw;
            width: 20vw;
            height: 35vh;
            margin: 1;
        }
    }
}
