.ChangePasswordPage {
    border: 1px solid black;
    background-color: rgba(243, 243, 243, 0.938);
    height: 100vh;
    width: 98.9vw;
    position: fixed;
    left: -0.1vw;
    top: -0.1vh;
}

.ChangePasswordPage .UnVerified, .ChangePasswordPage .Verified {
    background-color: white;
    border: 1px solid white;
    border-radius: 20px;
    padding: 20px;
    width: 40vw;
    height: 20vh;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    top: 25vh;
}

.ChangePasswordPage .Verified {
    height: 32vh;
}

.ChangePasswordPage .UnVerified .image-div {
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
}

.ChangePasswordPage .UnVerified .unverify {
    font-size: 2vw;
    text-align: center;
    margin-top: 15px;
}

.ChangePasswordPage .UnVerified .claim {
    width: fit-content;
    margin-top: 10vh;
    margin-right: auto;
    margin-left: auto;
}

.ChangePasswordPage .UnVerified .claim a {
    text-decoration: none;
    color: black;
}

.ChangePasswordPage .Verified .verify {
    display: flex;
    flex-direction: column;
}

.ChangePasswordPage .Verified .verify input {
    border: 1px solid gainsboro;
    border-radius: 20px;
    padding: 20px;
    width: 20vw;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}

.ChangePasswordPage .Verified .verify .confirm-password {
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid rgb(126, 218, 126);
    border-radius: 20px;
    padding: 20px;
    color: white;
    font-size: 25px;
    background-color: rgb(126, 218, 126);
    cursor: pointer;
}

.ChangePasswordPage .Verified .verify .confirm-password:hover {
    background-color: green;
    border-color: green;
}
