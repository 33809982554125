@media only screen and (max-width: 768px) {
    .HomePage {
        .header {
            height: 45vh;

            .header-img {
                top: -25vh;

                img {
                    height: 65vw;
                }

                .shadow {
                    top: -16vh;
                    height: 15vh;
                }
            }

            .action-container {
                top: 7vh;
            }
        }

        .our-services {
            .split-text {
                &-container {
                    .title {
                        .text-platform {
                            top: -6vw;
                            font-size: 7.5vw;
                        }
                    }

                    .card-offer {
                        width: 35vw;

                        svg {
                            width: 10vw;
                            height: 10vw;
                        }

                        .title {
                            font-size: 3vw;
                        }

                        .description {
                            font-size: 1.5vw;
                        }
                    }
                }
            }
        }
    }
}
