@import url("https://fonts.googleapis.com/css2?family=Expletus+Sans:ital,wght@0,400..700;1,400..700&display=swap");

.about-page {
    .content {
        margin-top: 4vw;
        display: flex;
        flex-direction: row-reverse;

        .team-body {
            border: 1px solid rgb(214, 23, 151);
            border-radius: 2vw;
            width: 30vw;
            height: fit-content;
            padding: 2vw;
            margin-top: 8vw;

            .title {
                font-size: 2vw;
                color: rgb(0, 0, 0);
                text-align: center;
                background-image: linear-gradient(to bottom right, #ff5de5, #ef4444);
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;
                font-family: "Expletus Sans";
            }

            .container {
                display: flex;
                flex-direction: column;

                .seperator {
                    height: 0.1vw;
                    background: linear-gradient(to bottom right, #eb3072, #2e041e);
                    border-radius: 1vw;
                    margin-bottom: 2vw;
                }

                .team-member {
                    display: flex;
                    flex-direction: row;
                    margin-top: 2vw;
                    margin-bottom: 2vw;
                    gap: 2vw;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 2vw;
                        height: 2vw;
                        border-radius: 100%;
                        padding: 1vw;
                        border: 1px solid rgb(214, 23, 151);
                        margin-top: auto;
                        margin-bottom: auto;
                    }

                    .name {
                        color: rgb(26, 26, 26);
                        font-size: 2vw;
                        text-align: center;
                    }

                    .title {
                        font-size: 1.5vw;
                    }
                }
            }
        }

        .body {
            color: rgb(0, 0, 0);
            width: 60vw;

            .buldge {
                width: 1vw;
                height: 4vw;
                background: linear-gradient(to bottom right, #ff5de5, #ef4444);
            }

            .header {
                display: flex;
                flex-direction: row;
                gap: 2vw;

                .title {
                    color: rgb(0, 0, 0);
                    font-size: 3vw;
                    margin-top: auto;
                    margin-bottom: auto;
                    text-align: center;
                }

                .button {
                    background: linear-gradient(to bottom right, #eb2232, #850ed4);
                    width: 8vw;
                    border-radius: 1vw;
                    padding: 0.8vw;
                    text-align: center;
                    align-items: center;
                    color: white;
                    font-size: 1.5vw;
                    font-family: "Inter";
                    cursor: pointer;
                    height: fit-content;
                    margin-top: auto;
                    margin-bottom: auto;
        
                    &:hover {
                        background: linear-gradient(to bottom left, #eb2232, #850ed4);
                    }
                }
            }

            .content {
                width: 55vw;
                display: flex;
                flex-direction: column;

                .container {
                    width: 55vw;
                    margin-top: 2vw;
                    margin-bottom: 2vw;

                    .title {
                        font-size: 2vw;
                        color: white;
                        background-image: linear-gradient(to bottom right, #ff5de5, #ef4444);
                        -webkit-background-clip: text;
                        background-clip: text;
                        color: transparent;
                        font-family: "Expletus Sans";
                    }

                    .text-container {
                        display: flex;
                        flex-direction: row;
                        gap: 1vw;
                        margin-top: 2vw;

                        .buldge {
                            width: 0.5vw !important;
                            height: 9vw !important;
                            background: linear-gradient(to bottom right, #ff5de5, #ef4444);
                        }

                        .text {
                            font-family: "Expletus Sans";
                            font-size: 1.2vw;
                        }
                    }
                }
            }
        }
    }
}