.event-review-card {
    background-color: #171717;
    height: 15vw;
    border-radius: 2vw;
    padding: 1vw;
    color: white !important;

    .card-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .seperator-np {
            display: flex;
            flex-direction: row;
            gap: 1vw;
            align-items: left;
            align-self: left;

            .name {
                margin-top: auto;
                margin-bottom: auto;
                font-size: 2vw;
            }

            img {
                width: 3vw;
                height: 3vw;
                border-radius: 3vw;
                margin-top: auto;
                margin-bottom: auto;
            }
        }

        .star-wrapper {
            font-size: 2vw;
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    .desc {
        font-size: 2vw;
        text-align: center;
        margin-top: 2vw;
    }

    .date-complete {
        position: absolute;
        bottom: 14vw;
    }
}