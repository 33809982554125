@media only screen and (max-width: 768px) {
    .about-page {
        .content {
            flex-direction: column-reverse;

            .team-body {
                width: 90vw;
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 5vw;

                .title {
                    font-size: 10vw;
                }

                .container {
                    display: grid;
                    grid-template-columns: 90vw;

                    .seperator {
                        display: none;
                    }

                    .team-member {
                        width: fit-content;
                        gap: 2vw;

                        img {
                            width: 10vw;
                            height: 10vw;
                        }

                        .details {
                            display: flex;
                            flex-direction: row;
                            gap: 2vw;
                        }

                        .name {
                            font-size: 6vw;
                            width: fit-content;
                        }

                        .title {
                            font-size: 3vw;
                            margin-top: auto;
                            margin-bottom: auto;
                        }
                    }
                }
            }

            .body {
                width: 99vw;

                .buldge {
                    width: 2vw;
                    height: 20vw;
                }

                .header {
                    gap: 4vw;

                    .title {
                        font-size: 12vw;
                    }

                    .button {
                        width: 30vw;
                        font-size: 5vw;
                    }
                }

                .content {
                    width: 90vw;
                    margin-right: auto;
                    margin-left: auto;

                    .container {
                        width: 90vw;

                        .title {
                            font-size: 8vw;
                        }

                        .text-container {
                            .buldge {
                                width: 8vw !important;
                                height: 35vw !important;
                            }

                            .text {
                                font-size: 3vw;
                                margin-top: auto;
                                margin-bottom: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}
