@media only screen and (max-width: 768px) {
    .EventsCard {
        width: 60vw;
        min-height: 65vw;
        height: fit-content;
        max-height: 80vw;
        border-radius: 1vw;

        .extra-card {
            width: 60vw;

            .event-today {
                font-size: 3vw;
                padding: 2vw;
                top: 2vw;
                left: 32vw;
            }

            .event-passed {
                font-size: 8vw;
                width: 68vw;
                height: 12vw;
                top: 25vw;
                left: -5vw;
                transform: rotate(47deg);
            }
        }

        .banner {
            width: 60vw;
            height: 25vh;
        }

        .title {
            font-size: 3vw !important;
            color: rgb(207, 10, 89) !important;
            margin-top: 1vh;
            margin-bottom: 1vh;
        }

        .desc {
            font-size: 2.5vw;
        }

        .loc-url {
            margin-top: 1.5vh;

            .loc-C {
                .orgName {
                    font-size: 2.5vw;
                }

                img {
                    width: 5vw;
                    height: 5vw;
                }
            }

            .date {
                font-size: 2.5vw;
            }

            .cost-range {
                font-size: 2.5vw;
            }

            .url-ico {
                width: 5.5vw;
                height: 5.5vw;
            }
        }
    }
}