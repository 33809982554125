@import url('https://fonts.googleapis.com/css2?family=Expletus+Sans:ital,wght@0,400..700;1,400..700&display=swap');

.purchase-event-ticket {
    position: fixed;
    top: 0;
    z-index: 3;

    .shade {
        width: 99vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.72);
        cursor: pointer;
    }

    .next-buttons-container {
        display: none;
    }

    .check-out {
        z-index: 4;
        background: #070707;
        position: fixed;
        top: 15vh;
        left: 10vw;
        width: 80vw;
        height: 70vh;
        border-radius: 1vw;
        padding: 1vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .tickets-container {
            width: 70%;
            height: 70vh;
            overflow-y: auto;

            .ticket {
                background: #181818;
                width: 80%;
                border-radius: 1vw;
                padding: 2vw;
                margin-right: auto;
                margin-left: auto;
                margin-top: 2vw;
                margin-bottom: 2vw;
                font-family: "Expletus Sans";

                .title-amount {
                    .title-count {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                       
                        .title {
                            color: white;
                            font-size: 2vw;
                        }

                        .count {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            gap: 2vw;

                            div {
                                font-size: 1.5vw;
                                color: white;
                                margin-top: auto;
                                margin-bottom: auto;
                                padding: 0.5vw;
                                border-radius: 0.5vw;
                                height: 1vw;
                                width: 1vw;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }

                            .minus {
                                background: gray;
                                color: white;
                                cursor: pointer;

                                &:hover {
                                    background: #181818;
                                    border: 1px solid white;
                                }
                            }

                            .plus {
                                background: linear-gradient(to bottom right, #FB4F8C, #510641);
                                color: white;
                                cursor: pointer;

                                &:hover {
                                    background: linear-gradient(to top right, #FB4F8C, #510641);
                                }
                            }
                        }
                    }

                    .cost-limit {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 1vw;
                        
                        .cost {
                            background-image: linear-gradient(to bottom right, #FB4F8C, #51062A);
                            -webkit-background-clip: text;
                            background-clip: text;
                            color: transparent;
                            font-size: 1.5vw;
                            margin-bottom: 1vw;
                            margin-top: 1vw;
                        }

                        .stats {
                            background:  linear-gradient(to bottom right, #FB4F8C, #51062A);
                            color: white;
                            font-size: 1vw;
                            width: fit-content;
                            padding: 0.5vw;
                            border-radius: 2vw;
                        }
                    }
                }

                .desc {
                    color: white;
                    font-size: 1vw;
                }
            }
        }

        &-form {
            width: 32vw;
            margin-right: 1vw;
            margin-left: 1vw;

            .content-container {
                width: 32vw;
                height: 25.5vw;
                overflow-y: auto;
                overflow-x: hidden;
                margin-right: 1vw;
                margin-left: 1vw;
                
                .slide {
                    .header {
                        background: #181818;
                        padding: 1vw;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        border-radius: 1vw;
                        margin-bottom: 1vw;
                        cursor: pointer;
    
                        svg {
                            width: 2vw;
                            height: 2vw;
                            fill: rgb(65, 65, 65);
                        }
                        
                        .title {
                            color: white;
                            font-family: "Expletus Sans";
                            font-size: 1.5vw;
                        }
                    }

                    .content {
                        &.checkout {
                            display: flex;
                            flex-direction: column;
                            width: 30vw;
                            max-height: 15vw;
                            overflow-y: auto;
                            margin-bottom: 1vw;

                            .content-item {
                                color: white;
                                background: rgb(41, 41, 41);
                                border-radius: 1vw;
                                width: fit-content;
                                padding: 1vw;
                                font-size: 2vw;
                                font-style: italic;
                                margin-bottom: 1vw;
                                margin-right: auto;
                                margin-left: auto;
                            }
                        }

                        .StripeElement {
                            width: 28vw;
                            margin-left: 1.5vw;
                        }
                    }

                    &.inactive {
                        .header {
                            svg {
                                transform: rotate(-180deg);
                            }
                        }

                        .content {
                            display: none;
                        }
                    }
                }
    
                .form-group {
                    margin-bottom: 2vw;
                    width: 31.5vw;
                    display: flex;
                    flex-direction: column;
    
                    input {
                        background: #181818;
                        width: 25vw;
                        height: 2vw;
                        padding: 1vw;
                        font-size: 1.5vw;
                        border-radius: 1vw;
                        margin-bottom: 0.5vw;
                        margin-top: 0.5vw;
                        margin-right: auto;
                        margin-left: auto;
                        color: white;
                    }
                }
            }

            .button {
                background-image: linear-gradient(to bottom right, #FF5DE5, #EF4444);
                width: 15vw;
                margin-right: auto;
                margin-left: auto;
                margin-top: 3vw;
                text-align: center;
                color: white;
                font-size: 2vw;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1vw;
                outline: none;
                border: none;
                border-radius: 1vw;
                cursor: pointer;

                &:hover {
                    background-image: linear-gradient(to top left, #FF5DE5, #EF4444);
                }
            }
        }
    }
}
