@media only screen and (max-width: 768px) {
    .footer {
        .top-row {
            .logo-links {
                .logo {
                    font-size: 4vw;
                }

                .links a {
                    font-size: 2.5vw;
                }
            }

            .moto {
                font-size: 3vw;
            }

            .social-links {
                .title {
                    font-size: 2vw;
                }

                .links {
                    svg {
                        width: 5vw;
                        height: 5vw;
                        margin-left: 0.5vw;
                        margin-right: 0.5vw;
                    }
                }
            }
        }

        .bottom-row {
            font-size: 4vw;
        }
    }
}
